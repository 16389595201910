<div class="new-app-main-banner-wrap-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-wrap-content">
                    <span class="sub-title">#Get Your 14 Days Free Trial</span>
                    <h1>O melhor APP para seus cliente gerenciar veículos de qualquer lugar e a qualquer hora!</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id tincidunt eifend odio viverra diam
                        aliquet donec again.</p>
                    <ul class="user-info">
                        <li><img src="assets/img/user/user1.jpg" class="rounded-circle" alt="image"></li>
                        <li><img src="assets/img/user/user2.jpg" class="rounded-circle" alt="image"></li>
                        <li><img src="assets/img/user/user3.jpg" class="rounded-circle" alt="image"></li>
                        <li><img src="assets/img/user/user4.jpg" class="rounded-circle" alt="image"></li>
                        <li class="title">4K+ used this App</li>
                    </ul>
                    <div class="app-btn-box">
                        <a href="#" class="applestore-btn" target="_blank">
                            <img src="assets/img/apple-store.png" alt="image">
                            Download on the
                            <span>Apple Store</span>
                        </a>
                        <a href="#" class="playstore-btn" target="_blank">
                            <img src="assets/img/play-store.png" alt="image">
                            Get It On
                            <span>Google Play</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-wrap-image">
                    <img src="assets/img/more-home/banner/banner-2.png" alt="image">
                    <div class="wrap-image-shape-1">
                        <img src="assets/img/more-home/banner/shape-3.png" alt="image">
                    </div>
                    <div class="wrap-image-shape-2">
                        <img src="assets/img/more-home/banner/shape-4.png" alt="image">
                    </div>
                    <div class="banner-circle">
                        <img src="assets/img/more-home/banner/banner-circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="new-app-main-banner-wrap-shape">
        <img src="assets/img/more-home/banner/shape-5.png" alt="image">
    </div>
</div>

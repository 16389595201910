<div class="container">
  <div class="app-download-inner bg-gray">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="app-download-content">
          <span class="sub-title">DOWNLOAD APP</span>
          <h2>Baixe o aplicativo Prosyst gratuitamente </h2>
          <p>Para fazer o download do aplicativo, basta estar logado na sua conta,
            acessar a loja de aplicativos do seu celular: Play Store ou Apple Store, pesquisar
            por Prosyst Rastreamento e fazer a instalação. É rápido e prático!
          </p>
          <div class="btn-box">
            <a href="https://play.google.com/store/apps/details?id=br.com.prosyst" class="playstore-btn"
              target="_blank">
              <img src="assets/img/play-store.png" alt="image">
              <span>Google Play</span>
            </a>
            <a href="https://apps.apple.com/br/app/prosyst-rastreamento/id1659899859" class="applestore-btn"
              target="_blank">
              <img src="assets/img/apple-store.png" alt="image">
              <span>Apple Store</span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="app-download-image">
          <img src="assets/img/app/app-img4.png" alt="app-img">
        </div>
      </div>
    </div>
  </div>
</div>
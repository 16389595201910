<div class="app-progress-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="app-progress-image text-center">
          <img src="assets/img/app/app-img2.png" alt="app-img">
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="app-progress-content">
          <span class="sub-title">Plataforma em constante evolução</span>
          <h2>Todas as soluções em um só lugar e pensadas de forma exclusiva para você</h2>
          <p>A nossa plataforma possui o diferencial de concentrar tudo o que
            empresas de rastreamento precisam em um só lugar.</p>
          <p>
            Com o sistema da Prosyst, é possível controlar toda a parte administrativa e
            operacional de cada empresa.
          </p>
          <a target="_blank"
            href="https://api.whatsapp.com/send/?phone=5585988646989&text&type=phone_number&text=Olá, estou interessado em uma demonstração do sistema&app_absent=0"
            class="default-btn">Falar com um especialista</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <app-navbar-style-one></app-navbar-style-one> -->

<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Perguntas Frequentes</h2>
      <!-- <ul>
        <li><a routerLink="/">Home</a></li>
        <li>FAQ</li>
      </ul> -->
    </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="faq-area ptb-100">
  <div class="container">
    <div class="row">
      <!-- <div class="col-lg-4 col-md-12">
        <div class="faq-sidebar">
          <ul>
            <li><a routerLink="/faq"><span>Startup Business Idea</span></a></li>
            <li><a routerLink="/faq"><span>Digital Marketing</span></a></li>
            <li><a routerLink="/faq" class="active"><span>Design and Development</span></a></li>
            <li><a routerLink="/faq"><span>IT and Startup Consulting</span></a></li>
            <li><a routerLink="/faq"><span>Design and Development</span></a></li>
            <li><a routerLink="/faq"><span>Startup Landing Page</span></a></li>
          </ul>
        </div>
      </div> -->
      <div class="col-lg-8 col-md-12">
        <div class="faq-accordion accordion">
          <div class="accordion">
            <div class="accordion-section">
              <div class="accordion-header" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }">
                • O que é um rastreador?
              </div>
              <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                <p>Um rastreador é um equipamento utilizado em veículos para monitorar informações
                  logísticas e geográficas. Ele oferece a localização do mesmo, seja para fins de
                  segurança, controle de frota ou otimização dos procedimentos logísticos.</p>
              </div>
            </div>
            <div class="accordion-section">
              <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                • Qual a diferença entre GPS e GPRS?
              </div>
              <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                <p>
                  O GPS é um Sistema de Posicionamento Global, composto por 24 satélites que
                  estão em órbita e se movem constantemente, a aproximadamente 20.000 km da
                  superfície da terra. Os satélites transmitem para o equipamento (rastreador)
                  informações de horário, data, velocidade e posição (longitude e latitude).
                </p>
                <p>Já o GPRS, General Packet Radio Service, é um serviço que permite o envio e a
                  recepção de dados através de uma rede telefônica móvel.</p>
              </div>
            </div>
            <div class="accordion-section">
              <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                • Qual é a cobertura do serviço de rastreamento?
              </div>
              <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                <p>Nosso serviço de rastreamento tem cobertura GPS em todo o território nacional.
                  Podendo sofrer ausência de sinal GPRS, de acordo com a cobertura da operadora
                  de telefonia móvel que utilizamos.</p>
              </div>
            </div>
            <div class="accordion-section">
              <div class="accordion-header" (click)="toggleSection(3)" [ngClass]="{ 'open': isSectionOpen(3) }">
                • O rastreador pode ser instalado em qualquer veículo?
              </div>
              <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                <p>Sim. Qualquer veículo pode ser rastreado, seja ele de passeio, utilitário, carros,
                  motos ou caminhões.</p>
              </div>
            </div>
            <div class="accordion-section">
              <div class="accordion-header" (click)="toggleSection(4)" [ngClass]="{ 'open': isSectionOpen(4) }">
                • O rastreador interfere no sistema eletrônico do veículo?
              </div>
              <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                <p>Não. A instalação é feita seguindo rigorosas normas técnicas e não há interferência
                  no sistema eletrônico do veículo.</p>
              </div>
            </div>
            <div class="accordion-section">
              <div class="accordion-header" (click)="toggleSection(5)" [ngClass]="{ 'open': isSectionOpen(5) }">
                • Quais dados são coletados pelo aplicativo?
              </div>
              <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(5) }">
                <p>O aplicativo acessa a localização precisa do aparelho apenas para visualização em
                  tempo real no mapa. Não são transferidas, armazenadas ou compartilhadas
                  nenhuma das informações referentes a sua localização. Não é coletado nenhum
                  outro dado fornecido pelo seu aparelho. Seus dados estarão armazenados, de
                  forma criptografada, em um ambiente seguro e controlado.</p>
              </div>
            </div>
            <div class="accordion-section">
              <div class="accordion-header" (click)="toggleSection(6)" [ngClass]="{ 'open': isSectionOpen(6) }">
                • O que acontece se o equipamento não encontrar sinal de celular?
              </div>
              <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(6) }">
                <p>A maioria dos equipamentos armazena todas as coordenadas na sua memória
                  interna e transmite os dados armazenados assim que o equipamento entrar na área
                  de cobertura GPRS.</p>
              </div>
            </div>
            <div class="accordion-section">
              <div class="accordion-header" (click)="toggleSection(7)" [ngClass]="{ 'open': isSectionOpen(7) }">
                • Em quais situações o equipamento pode não obter localização?
              </div>
              <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(7) }">
                <P>Normalmente, isso acontece em estacionamentos de subsolo, túneis, locais
                  fechados ou em casos extremos, com interferência de alguns tipos dispositivos,
                  como jammers.</P>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-contact-info></app-contact-info> -->

<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- <div class="features-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon">
                        <i class="ri-smartphone-line"></i>
                    </div>
                    <h3>User Friendly</h3>
                    <p>Most probably best dashboard design for your business you can try.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon bg2">
                        <i class="ri-award-line"></i>
                    </div>
                    <h3>Award Winning App</h3>
                    <p>Most probably best dashboard design for your business you can try.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon bg3">
                        <i class="ri-fingerprint-line"></i>
                    </div>
                    <h3>Privacy Protected</h3>
                    <p>Most probably best dashboard design for your business you can try.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon bg4">
                        <i class="ri-vip-diamond-line"></i>
                    </div>
                    <h3>Lifetime Update</h3>
                    <p>Most probably best dashboard design for your business you can try.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="overview-area">
  <div class="container-fluid">
    <div class="row m-0">
      <div class="col-xl-6 col-lg-12 col-md-12 p-0">
        <div class="overview-content">
          <h2>Gestão completa para a sua empresa!</h2>
          <p>Entregamos o módulo operacional (rastreamento) e o sistema de gestão
            (ERP), tudo em um único lugar. Vamos além do simples monitoramento de
            localização. Nosso aplicativo possui várias funcionalidades, a fim de proporcionar
            uma gestão completa, melhorando a segurança e permitindo a integração de
            processos que irão aprimorar a eficiência operacional do seu negócio.</p>
          <ul class="features-list">
            <li>
              <div class="icon">
                <i class="ri-shield-star-line"></i>
              </div>
              <h3>Segurança Veicular</h3>
              <p>Localização em tempo real: Permite saber a localização exata do veículo a
                qualquer momento, sendo uma importante vantagem em casos de roubo ou
                furto.</p>
              <p>Recuperação rápida: Facilita a recuperação de veículos roubados, aumentando as chances de sucesso</p>
              <p>Proteção do ativo: utilizando equipamentos especializados, garantindo sua segurança e localização.</p>
            </li>
            <li>
              <div class="icon">
                <i class="ri-award-line"></i>
              </div>
              <h3>Eficiência Operacional</h3>
              <p>Controle de velocidade: Ajuda a monitorar e controlar a velocidade dos veículos, reduzindo o risco de
                acidentes, multas e economizando combustível.</p>
              <p>Manutenção preventiva: Permite agendar manutenções preventivas com base no desempenho real do veículo,
                prolongando sua vida útil.</p>
              <p>Monitoramento da frota: Permite às empresas monitorar toda a frota em tempo real, facilitando a
                gestão e a tomada de decisões futuras.</p>
            </li>
            <li>
              <div class="icon">
                <i class="ri-arrow-right-down-fill"></i>
              </div>
              <h3>Redução de Custos:</h3>
              <p>Economia de combustível: Ao otimizar rotas e monitorar o comportamento do motorista, é possível reduzir
                os custos com combustível.</p>
              <p>Manutenção eficiente: A manutenção preventiva ajuda a evitar falhas inesperadas, reduzindo os custos de
                reparo.</p>
            </li>
          </ul>
          <!-- <div class="btn-box">
            <a routerLink="/sign-in" class="default-btn">Start Free Trial</a>
            <a routerLink="/features-2" class="link-btn">See All Features</a>
          </div> -->
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12 p-0">
        <div class="overview-image bg2">
          <img src="https://angular.envytheme.com/apzel-ng/assets/img/overview2.jpg" alt="overview">
        </div>
      </div>
    </div>
  </div>
</div>
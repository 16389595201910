<div class="partner-slides">
  <owl-carousel-o [options]="partnerSlides">
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/prosyst/partners/1.png" alt="image">
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/prosyst/partners/2.png" alt="image">
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/prosyst/partners/3.png" alt="image">
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/prosyst/partners/4.png" alt="image">
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/prosyst/partners/5.jpg" alt="image">
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img style="opacity: 83%;width: 127px !important;border-radius: 25px;"
            src="assets/img/prosyst/partners/gt06-logo.png" alt="image">
        </a>
      </div>
    </ng-template>
    <!-- <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/prosyst/partners/logo-mxt.png" alt="image">
        </a>
      </div>
    </ng-template> -->

  </owl-carousel-o>
</div>
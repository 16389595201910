<!-- <app-navbar-style-one></app-navbar-style-one> -->
<!--
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Contact Us</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>Contact Us</li>
      </ul>
    </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div> -->

<app-contact-info></app-contact-info>

<div class="contact-area ptb-100">
  <!--   <div class="container">
    <div class="section-title">
      <h2>Get in Touch</h2>
      <p>The IT industry offers a sea of options, from platforms, programming languages methodologies, technologies,
        tools, and more.</p>
    </div>
    <div class="contact-form">
      <form id="contactForm">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <input type="text" name="name" class="form-control" placeholder="Eg: Sarah Taylor">
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <input type="email" name="email" class="form-control" placeholder="hello@sarah.com">
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <input type="text" name="phone_number" class="form-control" placeholder="Enter your phone number">
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <input type="text" name="msg_subject" class="form-control" placeholder="Enter your subject">
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <textarea name="message" class="form-control" cols="30" rows="6"
                placeholder="Enter message..."></textarea>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <button type="submit" class="default-btn"><i class='bx bx-paper-plane'></i> Send Message</button>
          </div>
        </div>
      </form>
    </div>
  </div> -->

  <div class="maps">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.333616470226!2d-38.50171352424317!3d-3.737286643219021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c74889affe6945%3A0x51cc07826be74728!2sBS%20Design%20Corporate%20Towers!5e0!3m2!1spt-BR!2sbr!4v1700320734088!5m2!1spt-BR!2sbr"></iframe>
  </div>
</div>


<!-- <app-footer-style-three></app-footer-style-three> -->
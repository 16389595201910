<app-navbar-style-three></app-navbar-style-three>

<app-homefive-banner id="home"></app-homefive-banner>

<app-homefive-features id="features"></app-homefive-features>

<!--<div class="screenshots-area pb-100">
  <app-screenshots-style-five></app-screenshots-style-five>
</div> -->

<app-about-simple-page id="about"></app-about-simple-page>

<!-- <app-pricing-style-five></app-pricing-style-five> -->

<div class="app-download-area ptb-100">
  <app-download-app-style-two></app-download-app-style-two>
</div>

<!-- <app-feedback-style-four></app-feedback-style-four> -->

<!-- <div class="free-trial-area pt-100">
  <app-free-trial-style-three></app-free-trial-style-three>
</div> -->

<!-- <app-blog></app-blog> -->

<app-faq-page id="faq"></app-faq-page>

<app-contact-page id="contact"></app-contact-page>

<app-footer-style-one></app-footer-style-one>
<div class="container">
  <div class="section-title">
    <!-- <span class="sub-title">Soluções</span> -->
    <h2>As melhores soluções para o seu cliente e a sua empresa!</h2>
  </div>
  <div class="section-title">
    <span class="sub-title">Para seu cliente:</span>
  </div>
  <div class="row justify-content-center">
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon bg3">
          <i class="ri-checkbox-blank-circle-line"></i>
        </div>
        <h3>Cerca Eletrônica</h3>
        <p>Delimite uma área específica e seja notificado quando o veículo sair da área protegida.</p>
      </div>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon bg3">
          <i class="ri-alarm-warning-line"></i>
        </div>
        <h3>Alerta de Ignição</h3>
        <p>Seja notificado quando a ignição do veículo for acionada.</p>
      </div>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon bg3">
          <i class="ri-treasure-map-line"></i>
        </div>
        <h3>Rastro e Estatísticas</h3>
        <p>Acompanhamento e monitoramento da localização do seu veículo. Pode ser consultada em tempo real ou por um
          período específico.</p>
      </div>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon bg3">
          <i class="ri-rotate-lock-line"></i>
        </div>
        <h3>Bloqueio e Desbloqueio</h3>
        <p>Controle remotamente as funções das ignições ou sistemas de partida do seu veículo.</p>
      </div>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon bg3">
          <i class="ri-file-chart-line"></i>
        </div>
        <h3>Relatórios para Frotas</h3>
        <p>Gerencia a eficiência de frotas por meio de relatórios estatísticos, de consumo, medição de atividade dos
          veículos e mais.</p>
      </div>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon bg3">
          <i class="ri-wallet-line"></i>
        </div>
        <h3>Pagamento pelo Aplicativo</h3>
        <p>Configure sua forma de pagamento pelo app e acompanhe todo seu histórico financeiro.</p>
      </div>
    </div>
  </div>

  <div class="section-title">
    <span class="sub-title">Para gestão da sua empresa:</span>
  </div>
  <div class="row justify-content-center">
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon">
          <i class="ri-money-dollar-circle-line"></i>
        </div>
        <h3>Financeiro</h3>
        <p>Tenha o controle de toda a parte financeira na palma da sua mão, pelo aplicativo
          Prosyst. Contas a Pagar, Contas a Receber e afins.</p>
      </div>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon">
          <i class="ri-secure-payment-line"></i>
        </div>
        <h3>Boleto e Cartão</h3>
        <p>Receba de seus clientes via boleto ou cartão.</p>
      </div>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon bg5">
          <i class="ri-archive-line"></i>
        </div>
        <h3>Estoque</h3>
        <p>Organize e controle a quantidade de produtos disponíveis por filial.</p>
      </div>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon bg6">
          <i class="ri-user-add-line"></i>
        </div>
        <h3>Bonificação</h3>
        <p>Ao indicar um prospect e ele se tornar cliente, quem indicou receberá um bônus.
          Esse bônus será utilizado como desconto no faturamento do próximo boleto.</p>
      </div>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4">
      <div class="features-item with-border">
        <div class="icon">
          <i class="ri-bill-line"></i>
        </div>
        <h3>Faturamento</h3>
        <p>Emissão de NFs de instalação, manutenção e mensalidade de forma individual ou em lote. Possibilita lançamentos avulsos ou parcelados, cancelamentos e baixas manuais de títulos e notas fiscais.</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-2 col-sm-4 col-md-4">
        <div class="features-item with-border">
          <div class="icon">
            <i class="ri-flow-chart"></i>
          </div>
          <h3>CRM</h3>
          <p>Gerencie a aquisição de novos clientes para a empresa e monitore o processo de
            vendas, podendo criar ações de vendas mensais e futuras.</p>
        </div>
      </div>
      <div class="col-lg-2 col-sm-4 col-md-4">
        <div class="features-item with-border">
          <div class="icon bg6">
            <i class="ri-dashboard-line"></i>
          </div>
          <h3>Orçamento</h3>
          <p>Organize os orçamentos de instalação e manutenção de forma simples e gratuita.</p>
        </div>
      </div>
      <div class="col-lg-2 col-sm-4 col-md-4">
        <div class="features-item with-border">
          <div class="icon ">
            <i class="ri-sketching"></i>
          </div>
          <h3>Assinatura eletrônica</h3>
          <p>Possibilidade de aceite digital para clientes, facilitando e tornando
            mais acessíveis os processos de assinatura de contrato.</p>
        </div>
      </div>
      <div class="col-lg-2 col-sm-4 col-md-4">
        <div class="features-item with-border">
          <div class="icon ">
            <i class="ri-bank-line"></i>
          </div>
          <h3>Cobrança</h3>
          <p>Notificações personalizadas no aplicativo para avisos de aproximação das datas de
            pagamento.</p>
        </div>
      </div>
      <div class="col-lg-2 col-sm-4 col-md-4">
        <div class="features-item with-border">
          <div class="icon bg5">
            <i class="ri-tools-line"></i>
          </div>
          <h3>Ordem de Serviço</h3>
          <p>Mais controle e organização para todos os tipos de serviços ofertados e gestão dos atendimentos das equipes de campo.</p>
        </div>
      </div>
    </div>
  </div>
</div>